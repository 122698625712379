import {defineMessages} from 'react-intl';

const messages = defineMessages({
  approveError: {
    id: 'SmartSkills.assess.approveError',
    defaultMessage: 'Cannot approve skill'
  },
  approved: {
    id: 'SmartSkills.assess.approved',
    defaultMessage: 'Approval Date'
  },
  approvedBy: {
    id: 'SmartSkills.assess.approvedBy',
    defaultMessage: 'Approved by'
  },
  approveSuccess: {
    id: 'SmartSkills.assess.approveSuccess',
    defaultMessage: 'Skill approved'
  },
  assess: {
    id: 'SmartSkills.assess.assess',
    defaultMessage: 'Assess'
  },
  reAssess: {
    id: 'SmartSkills.assess.reAssess',
    defaultMessage: 'Re-Assess'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  completed: {
    id: 'SmartSkills.assess.completed',
    defaultMessage: 'Completed'
  },
  currentLevel: {
    id: 'SmartSkills.currentLevel',
    defaultMessage: 'Current Level'
  },
  selfAssessedLevel: {
    id: 'SmartSkills.selfAssessedLevel',
    defaultMessage: 'Self Assessed Level'
  },
  expires: {
    id: 'SmartSkills.expires',
    defaultMessage: 'Expiration Date'
  },
  feedback: {
    id: 'SmartSkills.assess.feedback',
    defaultMessage: 'Feedback'
  },
  feedbackPlaceholder: {
    id: 'SmartSkills.assess.feedbackPlaceholder',
    defaultMessage: 'Type comment here...'
  },
  levelReached: {
    id: 'SmartSkills.assess.levelReached',
    defaultMessage: 'Level Reached'
  },
  loadingMedia: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  noLevel: {
    id: 'SmartSkills.assess.noLevel',
    defaultMessage: 'No level'
  },
  ok: {
    id: 'SmartSkills.assess.ok',
    defaultMessage: 'OK, GOT IT'
  },
  selectLevel: {
    id: 'SmartSkills.assess.selectLevel',
    defaultMessage: 'Select Level'
  },
  setLevel: {
    id: 'SmartSkills.assess.setLevel',
    defaultMessage: 'Set New Level'
  },
  skillNotCompleted: {
    id: 'SmartSkills.assess.skillNotCompleted',
    defaultMessage: 'Skill is not completed yet.'
  },
  assessSkillDescription: {
    id: 'SmartSkills.user.assessSkillDescription',
    defaultMessage: 'Assess the Skill of your team member and make remarks on your findings.'
  },
  assessment: {
    id: 'SmartSkills.assessment',
    defaultMessage: 'Assessment'
  },
  assessSkill: {
    id: 'SmartSkills.user.assessSkill',
    defaultMessage: 'Assess Skill'
  },
  reAssessSkill: {
    id: 'SmartSkills.user.reAssessSkill',
    defaultMessage: 'Re-assess Skill'
  },
  skillDetailsDescription: {
    id: 'SmartSkills.user.skillDetailsDescription',
    defaultMessage: "See how {name}'s skill progressed."
  },
  skillDetails: {
    id: 'SmartSkills.user.skillDetails',
    defaultMessage: 'Skill Details'
  },
  updateError: {
    id: 'SmartSkills.assess.updateError',
    defaultMessage: 'Cannot update level of Skill'
  },
  updateSuccess: {
    id: 'SmartSkills.assess.updateSuccess',
    defaultMessage: 'Skill level updated'
  },
  yourAssessment: {
    id: 'SmartSkills.assess.yourAssessment',
    defaultMessage: 'Your assessment'
  },
  yourAssessmentDescription: {
    id: 'SmartSkills.assess.yourAssessmentDescription',
    defaultMessage: 'Choose the level you believe the Operator has achieved.'
  },
  historyOfLearning: {
    id: 'SmartSkills.assess.historyOfLearning',
    defaultMessage: 'History of Learning'
  },
  history: {
    id: 'SmartSkills.assess.history',
    defaultMessage: 'History'
  },
  proofTitle: {
    id: 'SmartSkills.assess.proofTitle',
    defaultMessage: 'Proof'
  },
  learningStatusTitle: {
    id: 'SmartSkills.assess.learningStatusTitle',
    defaultMessage: 'Learning Status'
  },
  noProofTitle: {
    id: 'SmartSkills.assess.noProofTitle',
    defaultMessage: 'No proof'
  },
  noProofDescription: {
    id: 'SmartSkills.assess.noProofDescription',
    defaultMessage: 'This member has not submitted any feedback yet.'
  },
  never: {
    id: 'SmartSkills.assess.never',
    defaultMessage: 'Never'
  },
  skill: {
    id: 'skill',
    defaultMessage: 'Skill'
  },
  lowerLevelInfo: {
    id: 'lowerLevelInfo',
    defaultMessage: "Your assessment suggests there's room for further skill development"
  },
  notProvided: {
    id: 'notProvided',
    defaultMessage: 'Not provided'
  },
  expired: {
    id: 'expired',
    defaultMessage: 'EXPIRED'
  },
  unableToLoadSkillLevels: {
    id: 'SmartSkills.unableToLoadSkillLevels',
    defaultMessage: 'Unable to load required level details'
  }
});

export default messages;
