import styled from 'styled-components';
import {Tabs} from 'antd';
import {commonPalette} from 'shared/styles/palette';

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    color: ${commonPalette.txGrey};
  }

  .ant-tabs-ink-bar {
    background-color: ${commonPalette.btnGreen};
    height: 3px;
    border-radius: 200px 200px 0 0;
  }

  .ant-tabs-nav {
    .ant-tabs-tab-active,
    .ant-tabs-tab:hover {
      color: ${commonPalette.btnGreen};
    }
  }
`;
