import React from 'react';
import {DrawerSubtitle, SpinnerContainer, TabsContainer} from './styles';
import {observer} from 'mobx-react';
import {SkillForUser} from 'api/skill-matrix/queries';
import {skillForUserOptions} from 'api/skill-matrix/query-options';
import {InjectedIntlProps, injectIntl} from 'react-intl';
import messages from './messages';
import {useStore} from 'hooks/useStore';
import {useQuery} from '@apollo/client';
import {Tabs, TabPane, useTabNavigation} from 'ui-components/Tabs';
import Spinner from 'shared/components/Spinner';
import {LearningStatusTab} from './LearningStatusTab';
import {ProofOfAssessmentTab} from './ProofOfAssessmentTab';

type SkillHistoryProps = {
  skill: {
    id: string;
  };
} & InjectedIntlProps;

const SkillHistory = ({skill, intl: {formatMessage}}: SkillHistoryProps) => {
  const store = useStore();
  const {activeKey, setTab} = useTabNavigation('historyTab');
  const {data, loading} = useQuery(SkillForUser, skillForUserOptions.options({store, skill}));
  const skillForUser = data?.skillForUser;
  const {skillTasks, selfAssessmentMessage} = skillForUser ?? {};
  const proofOfAssessmentEnabled = !!store.platform.developmentFeatureFlags.proofOfAssessment;

  const availableTabs = proofOfAssessmentEnabled ? ['proof', 'learning-status'] : ['learning-status'];
  const isTabAvailable = !!activeKey && availableTabs.includes(activeKey);
  const loadingAndNoData = loading && !data;

  const loadingPlaceholder = (
    <SpinnerContainer>
      <Spinner size={40} />
    </SpinnerContainer>
  );
  return (
    <>
      <DrawerSubtitle>{formatMessage(messages.history)}</DrawerSubtitle>
      <TabsContainer>
        <Tabs
          activeKey={isTabAvailable ? activeKey : availableTabs[0]}
          onTabClick={setTab}
          animated={{tabPane: true, inkBar: true}}
        >
          {proofOfAssessmentEnabled && (
            <TabPane tab={formatMessage(messages.proofTitle)} key="proof">
              {loadingAndNoData ? loadingPlaceholder : <ProofOfAssessmentTab message={selfAssessmentMessage} />}
            </TabPane>
          )}
          <TabPane tab={formatMessage(messages.learningStatusTitle)} key="learning-status">
            {loadingAndNoData ? loadingPlaceholder : <LearningStatusTab skillTasks={skillTasks} />}
          </TabPane>
        </Tabs>
      </TabsContainer>
    </>
  );
};

export default injectIntl(observer(SkillHistory));
