import React from 'react';
import {Tabs as AntdTabs} from 'antd';
import {StyledTabs} from './styles';

export type TabsProps = {
  children: React.ReactNode;
} & React.ComponentProps<typeof AntdTabs>;

export type TabPaneProps = {
  children: React.ReactNode;
} & React.ComponentProps<typeof StyledTabs.TabPane>;

export const Tabs = (props: TabsProps) => <StyledTabs {...props} />;
export const TabPane = (props: TabPaneProps) => <AntdTabs.TabPane {...props} />;
