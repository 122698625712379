import React from 'react';
import {InjectedIntlProps, injectIntl} from 'react-intl';
import {IconContainer, MessageCard} from './styles';
import Icon from 'ui-components/Layout/Icon';
import {Headline, InfoBox} from 'ui-components/InfoBox';
import messages from './messages';

type ProofTabContentsProps = {
  message?: string;
} & InjectedIntlProps;

export const ProofOfAssessmentTab = injectIntl(({intl: {formatMessage}, message}: ProofTabContentsProps) => {
  if (message) {
    return (
      <MessageCard>
        <IconContainer>
          <Icon name="material-chat" size={20} />
        </IconContainer>
        {message}
      </MessageCard>
    );
  }

  return (
    <InfoBox
      type="informational"
      content={
        <div>
          <Headline>{formatMessage(messages.noProofTitle)}</Headline>
          <p>{formatMessage(messages.noProofDescription)}</p>
        </div>
      }
    />
  );
});
