import styled from 'styled-components';
import Icon from 'ui-components/Icon';
import {commonPalette} from 'shared/styles/palette';

const variants = {
  informational: {
    iconId: 'info',
    iconColor: commonPalette.iconInformational,
    bannerColor: commonPalette.bannerInformational
  },

  warning: {
    iconId: 'warning',
    iconColor: commonPalette.iconWarning,
    bannerColor: commonPalette.bannerWarning
  },

  error: {
    iconId: 'error',
    iconColor: commonPalette.iconError,
    bannerColor: commonPalette.bannerError
  }
};

export const InfoBoxWrapper = styled.div`
  width: ${({size}) => (size === 'small' ? '90%' : '100%')};
  padding-top: 14px;
  padding-bottom: 18px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: ${({size}) => (size === 'small' ? '10px' : '')};
  margin-left: ${({size}) => (size === 'small' ? '18px' : '')};
  background: ${({type}) => variants[type]?.bannerColor};
  border-radius: ${({draft}) => (draft ? '' : '8px')};
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  line-height: 20px;
  display: inline-flex;
  z-index: 100;
`;
export const StyledIcon = styled(Icon)`
  color: ${({type}) => variants[type]?.iconColor};
  width: 20px;
  height: 20px;
`;

export const DraftButton = styled.button`
  align-items: center;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-end;
  outline: 0;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  margin-left: auto;
  color: ${({type}) => variants[type]?.iconColor};
  background-color: transparent;
  text-align: center;
  font-kerning: none;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
`;

export const Headline = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;
