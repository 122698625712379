import type {SkillLevel} from 'api/schema-types';
import gql from 'graphql-tag';

export const SkillForUser = gql`
  query skillForUser($id: String!, $userId: String!, $teamId: String) {
    skillForUser(id: $id, userId: $userId, teamId: $teamId) {
      id
      name
      approvedBy
      approvedAt
      expiresAt
      status
      selfAssessmentMessage
      currentLevel {
        id
        name
        position
      }
      skillTasks {
        id
        title
        status
      }
    }
  }
`;

export interface UserSkillMatrixData {
  userSkillMatrix: {
    id: string;
    name: string;
    skillSets: {
      id: string;
      name: string;
    };
    requiredLevel: SkillLevel;
    currentLevel?: SkillLevel;
    selfAssessmentLevel?: SkillLevel;
    status: string;
  }[];
}

export interface UserSkillMatrixVariables {
  teamId: string;
  userId: string;
}

export const UserSkillMatrix = gql`
  query userSkillMatrix($teamId: String!, $userId: String!) {
    userSkillMatrix(teamId: $teamId, userId: $userId) {
      id
      name
      skillSets {
        id
        name
      }
      requiredLevel {
        id
        name
        position
      }
      currentLevel {
        id
        name
        position
      }
      selfAssessmentLevel {
        id
        name
        position
      }
      status
    }
  }
`;

export const TeamSkillMatrix = gql`
  query teamSkillMatrix($teamId: String!) {
    teamSkillMatrix(teamId: $teamId) {
      id
      name
      skillProfiles {
        id
        name
        requiredLevel {
          id
          name
          position
        }
        skillRoles {
          id
          name
        }
      }
      users {
        user {
          id
          fullName
        }
        currentLevel {
          id
          name
          position
        }
        status
      }
    }
  }
`;

export const SkillMatrix = gql`
  query skillMatrix {
    skillMatrix {
      id
      name
      members
      skills {
        skillsCount
        totalSkillsAssigned
        levels {
          count
          approvedCount
          level {
            id
            name
            position
          }
        }
      }
    }
  }
`;

export const SkillLevels = gql`
  query skillLevels {
    skillLevels {
      id
      name
      position
      description
    }
  }
`;

export const SkillRoles = gql`
  query skillRoles {
    skillRoles {
      id
      name
    }
  }
`;

export const PaginatedSkillMatrix = gql`
  #graphql
  query paginatedSkillMatrix($offset: Int!, $limit: Int, $sortBy: [PaginatedSkillMatrixSortBy!]) {
    paginatedSkillMatrix(offset: $offset, limit: $limit, sortBy: $sortBy) {
      totalCount
      results {
        id
        name
        members
        skills {
          skillsCount
          totalSkillsAssigned
          levels {
            count
            approvedCount
            level {
              id
              name
              position
            }
          }
        }
      }
    }
  }
`;
