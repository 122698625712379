import React, {useMemo} from 'react';
import {InjectedIntl, injectIntl} from 'react-intl';
import messages from './messages';
import {Card, CardTitle, Task, TaskCheckmark, TaskList, TextSecondary} from './styles';
import Icon from 'ui-components/Layout/Icon';

const isCompleted = (task: {status: string | null}) => {
  return task.status === 'COMPLETED';
};

type LearningStatusTabProps = {
  skillTasks?: {
    id: string;
    title: string;
    status: string | null;
  }[];
  intl: InjectedIntl;
};

export const LearningStatusTab = injectIntl(({intl: {formatMessage}, skillTasks}: LearningStatusTabProps) => {
  const tasksCompletion = useMemo(() => {
    if (!skillTasks?.length) return '0';

    let completedTasks = 0;
    for (const task of skillTasks) {
      if (isCompleted(task)) {
        completedTasks++;
      }
    }

    const totalTasks = skillTasks.length;

    return `${completedTasks}/${totalTasks}`;
  }, [skillTasks]);

  return (
    <Card>
      <CardTitle>
        {formatMessage(messages.completed)} <TextSecondary>{tasksCompletion}</TextSecondary>
      </CardTitle>
      <TaskList>
        {skillTasks?.map(task => (
          <Task key={task.id}>
            <TaskCheckmark checked={isCompleted(task)}>
              <Icon name={'checkmark'} size={20} />
            </TaskCheckmark>
            {task.title}
          </Task>
        ))}
      </TaskList>
    </Card>
  );
});
