import {useStore} from 'hooks/useStore';

export const useTabNavigation = (queryParamKey: string) => {
  const store = useStore();
  const {router} = store;
  const {queryParams} = router;
  const activeKey = queryParams?.[queryParamKey];

  const navigateToTab = (key: string) => {
    if (!router.currentRoute || router.queryParams?.[queryParamKey] === key) {
      return;
    }
    router.goTo(router.currentRoute, router.params, {...queryParams, [queryParamKey]: key});
  };

  return {
    activeKey: typeof activeKey === 'string' ? activeKey : null,
    setTab: navigateToTab
  };
};
